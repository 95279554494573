import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material'
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import CloseIcon from '@mui/icons-material/Close'

export default function MobileBottomNav ({
  openDialog
  , handleFormOpen
  , isPositioning
  , formReset
  , handlePositioningState
  , openMobileLayers
  , toggleMobileLayers
}) {
  const location = useLocation()
  const { state: { token } } = location
  const navigate = useNavigate()
  const [value, setValue] = useState(0)

  return (
      <Box display='flex'>
      <Paper
        elevation={3}
        sx={{
          position   : 'fixed'
          , bottom   : 0
          , left     : 0
          , right    : 0
          , zIndex   : theme => theme.zIndex.drawer + 1
          , height   : theme => theme.mixins.toolbar.minHeight
        }}>
          <BottomNavigation
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
          <BottomNavigationAction
            showLabel={false}
            label="Home"
            value="home"
            icon={<HomeOutlinedIcon />}
            onClick={() => {
              if (location.pathname === '/home') {
                handleFormOpen(false)
                toggleMobileLayers(false)
                handlePositioningState(false)
                formReset()
              } else navigate('/home', { state: { token } })
              setValue(0)
            }}
          />
          {location.pathname === '/home' &&
            <>
              <BottomNavigationAction
                label="Search"
                value="search"
                icon={<SearchOutlinedIcon />}
              />
              <BottomNavigationAction
                label="Overlays"
                value="overlays"
                icon={openMobileLayers ? <CloseIcon /> : <LayersOutlinedIcon />}
                onClick={() => {
                  toggleMobileLayers()
                }}
              />
              <BottomNavigationAction
                label="Add crash"
                value="addCrash"
                icon={isPositioning ? <CloseIcon /> : <AddLocationOutlinedIcon />}
                onClick={() => {
                  if (isPositioning) {
                    handlePositioningState(false)
                    handleFormOpen(false)
                  } else openDialog()
                }}
              />
            </>}
          </BottomNavigation>
        </Paper>
      </Box>
  )
}
