export const randStr = (length = 4) =>
  Math.random().toString(36).substring(2, length + 2)

export const toCamelCase = str =>
  str
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '')

export const fromCamelCase = str =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toUpperCase() : letter.toLowerCase()
    )
