import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Box
  , Divider
  , Typography
  , useTheme
} from '@mui/material/'
import {
  GridRowModes
  , DataGrid
  , GridToolbarContainer
  , GridActionsCellItem
  , GridRowEditStopReasons
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'

// Editable fields by user
// name
// surname
// department
// password
// is_verified

// Example user
// id: 1
// username: ""
// name: ""
// surname: ""
// organization: 0
// department: ""
// phone: "00000000"
// email: ""
// type: 1
// create_date: "2024-09-06"
// is_verified: false

const paginationModel = { page: 0, pageSize: 5 }

function FakeToolbar () {
  return (
    <GridToolbarContainer>
      <div style={{ height: '1px' }} />
    </GridToolbarContainer>
  )
}

export default function UsersTable ({ usersRows, modifyUser }) {
  const location = useLocation()
  const organizations = location.state?.organizations || {}
  const theme = useTheme()
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})

  useEffect(() => {
    if (usersRows.length) setRows(usersRows)
  }, [usersRows])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // const handleDeleteClick = id => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel
      , [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false }
    console.log('UPD', updatedRow, 'OLD', oldRow)
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    modifyUser(updatedRow)
    handleCancelClick(newRow.id)
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field             : 'id'
      , headerName      : 'ID'
      , headerClassName : 'overload-header'
      , flex            : 0.5 // , minWidth: 50
    }
    , {
      field             : 'username'
      , headerName      : 'Username'
      , headerClassName : 'overload-header'
      , flex            : 1.5 // , minWidth: 150
    }
    , {
      field             : 'name'
      , headerName      : 'First name'
      , editable        : true
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'surname'
      , headerName      : 'Last name'
      , editable        : true
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'organization'
      , headerName      : 'Organization'
      , headerClassName : 'overload-header'
      , flex            : 1
      , valueGetter     : value => {
        if (value) {
          return organizations.find(o => o.value === value).label
        }
      }
    }
    , {
      field             : 'department'
      , headerName      : 'Department/Unit'
      , headerClassName : 'overload-header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (value, row) => row.organization === 1 ? value : null
    }
    , {
      field             : 'departmentAlt'
      , headerName      : 'Hospital/Healthcare facility'
      , headerClassName : 'overload-header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (_, row) => row.organization === 2 ? row.department : null
    }
    , {
      field             : 'phone'
      , headerName      : 'Phone'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'email'
      , headerName      : 'Email'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'create_date'
      , headerName      : 'Date created'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'verified'
      , headerName      : 'Verified?'
      , type            : 'boolean'
      , editable        : true
      , headerClassName : 'overload-header'
      , flex            : 0.75 // , minWidth: 75
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : 'Actions'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth: 100
      , cellClassName   : 'actions'
      , getActions      : ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h3" align="center">
        <Trans>Users list</Trans>
      </Typography>
      {/* <Divider flexItem sx={{ ml: '-50vw', width: '150vw' }}/> */}
      <Box
        width='100%'
        sx={{
          '& .overload-header': {
            backgroundColor   : theme.palette.primary.main
            , color           : theme.palette.primary.contrastText
          }
        }}
      >
        <DataGrid
          showColumnVerticalBorder={true}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => error}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            mt                              : 4
            , border                        : 0
            , '& .MuiDataGrid-menuIcon > *' : { color: theme.palette.primary.contrastText }
          }}
          slots={{ toolbar: FakeToolbar }}
        />
      </Box>
    </>
  )
}
