import { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { Trans, t } from '@lingui/macro'
import { useForm, FormProvider } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import {
  Alert
  , Box
  , Card
  , CardHeader
  , CardContent
  , CardActions
  , Collapse
  , Container
  , CssBaseline
  , Divider
  , IconButton
  , MenuItem
  , Paper
  , Skeleton
  , Table
  , TableBody
  , TableCell
  , TableContainer
  , TableHead
  , TablePagination
  , TableRow
  , TextField
  , Typography
  , useTheme
} from '@mui/material/'
import { tableCellClasses } from '@mui/material/TableCell'
import { BarPlot } from '@mui/x-charts/BarChart'
import { LinePlot } from '@mui/x-charts/LineChart'
import { PiePlot } from '@mui/x-charts/PieChart'
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis'
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import DesktopNav from '../components/desktop/DesktopNav'
import FilterForm from '../components/forms/FilterForm'

/*
  - Crash
  Collected by
  Officer Name/Operator Name
  Officer Department/Operator Hospital
  Date of Filling <----> Until <---->
  Crash date <----> Until <---->
  Crash month
  Light conditions
  Crash type
  ADaMS crash id
  Officer position
  Officer number
  Report number
  Report date <----> Until <---->
  Crash week day
  Wheather conditions
  Vehicle runaway
  Severity
*/

// eslint-disable-next-line no-restricted-globals
const statsBaseUrl = new URL('/api/statistics', location)
// eslint-disable-next-line no-restricted-globals
const allCrashesBaseUrl = new URL('/api/crash/all', location)

// const paginationModel = { page: 0, pageSize: 25 }

function TableSkel () {
  return ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
  )
}

export default function Statistics ({ isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = useMemo(() => location.state?.init || {}, [location.state?.init])
  const organizations = useMemo(() => location.state?.organizations || {}, [location.state?.organizations])
  const methods = useForm({
    defaultValues: useMemo(elements => elements, [])
  })
  const { _ } = useLingui()
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest } = useHttp()
  const [formModel, setFormModel] = useState({})
  const [formList, setFormList] = useState([])
  const [columns, setColumns] = useState([])
  const [columnGroupingModel, setColumnGroupingModel] = useState([])
  const [rows, setRows] = useState([])
  const [message, setMessage] = useState('')
  const [totalCrashes, setTotalCrashes] = useState({})
  const [user, setUser] = useState({})
  const [crashes, setCrashes] = useState([])
  const submitRef = useRef()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [charts, setCharts] = useState([
    {
      apiNum : 1
      , title  : 'Collisions by severity'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 2
      , title  : 'Road traffic fatalities per year'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 3
      , title  : 'Road traffic fatalities by gender'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 4
      , title  : 'Fatalities and serious injuries by road user type'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 5
      , title  : 'Number of collisions by day of the week'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 6
      , title  : 'Road functional class collision'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
    , {
      apiNum : 7
      , title  : 'Type of collision'
      , type   : 'bar' // [bar, line, pie]
      , data   : []
    }
  ])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor : theme.palette.common.black
      , color           : theme.palette.common.white
    }
    // , [`&.${tableCellClasses.body}`]: {
    //   fontSize: 14
    // }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover
      backgroundColor: theme.palette.action.selected
    }
    // hide last border
    , '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const ExpandMore = styled(props => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme }) => ({
    marginLeft : 'auto'
    , transition : theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
    , variants: [
      {
        props : ({ expand }) => !expand
        , style : {
          transform: 'rotate(0deg)'
        }
      }
      , {
        props : ({ expand }) => !!expand
        , style : {
          transform: 'rotate(180deg)'
        }
      }
    ]
  }))

  const addSpanFields = crashes => {
    let result = []

    let newFirstRow = 0
    let newUnitRow = 0

    // Loop through each entry in the dataset
    crashes.forEach((crash, i) => {
      const currentCrashId = crash['Crash id']
      const currentUnitId = crash.unit['Unit id']

      // Calculate crashSpan: Count how many consecutive rows share the same Crash id
      let crashSpan = 1
      for (let j = i + 1; j < crashes.length; j++) {
        if (crashes[j]['Crash id'] !== currentCrashId) break
        crashSpan++
      }

      // Calculate unitSpan: Count how many consecutive rows share the same Unit id
      let unitSpan = 1
      if (Object.keys(crash.unit).length) {
        for (let j = i + 1; j < crashes.length; j++) {
          if (crashes[j].unit['Unit id'] !== currentUnitId) break
          unitSpan++
        }
      }

      let newCrash = {}
      if (i !== newFirstRow) newCrash = { ...crash, crashSpan: 0 }
      else {
        // Add crashSpan to the current row
        newCrash = { ...crash, crashSpan }
        // Skip the rows that were already included in the crashSpan
        newFirstRow += crashSpan
      }
      if (i !== newUnitRow) newCrash = { ...newCrash, unitSpan: 0 }
      else {
        // Add unitSpan to the current row
        newCrash = { ...newCrash, unitSpan }
        // Skip the rows that were already included in the unitSpan
        newUnitRow += unitSpan
      }
      result = [...result, newCrash]
    })

    return result
    // } else {
    //   crashes.forEach(crash => {
    //   // Total rows for each crash
    //     const crashSpan = crash.units.reduce((acc, unit) => acc + unit.people.length, 0)

    //     result = [...result, { ...crash, crashSpan }]
    //   })

    //   return result
    // }
  }

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state: { token, init, organizations } })
    const allCrashesUrl = new URL(allCrashesBaseUrl)
    allCrashesUrl.searchParams.set('format', 'table')
    sendRequest({
      token
      , url           : allCrashesUrl
      , manageResData : res => {
        // Sorting and Adding id to each crash
        const sortedCrashes = res.crashes
          .sort((a, b) => a['Crash id'] - b['Crash id'])
          .sort((a, b) => a.unit['Unit id'] - b.unit['Unit id'])
          .map((crash, i) => ({ ...crash, id: i + 1 }))

        const preparedCrashes = addSpanFields(sortedCrashes)

        setCrashes(preparedCrashes)
      }
    })
  }, [token, logout, isMobile, navigate, sendRequest, init, organizations])

  useEffect(() => {
    const { configuration, user, totalCrashes } = init
    setUser(user)
    setTotalCrashes(totalCrashes)

    let configOptions = {}
    Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)
      .forEach(section => {
        configuration[section].elements.forEach(elem => {
          if (elem.options.length) configOptions = { ...configOptions, [elem.label]: elem.options }
        })
      })

    function mapObjectToLabels (object, config) {
      const getLabel = (configField, value) => {
        const item = config[configField]?.find(i => i.value === value)
        return item ? item.label : value // Return value if label not found
      }

      const mappedobject = {}

      Object.keys(object).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const value = object[key]

          if (typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length) {
            // Recursively map nested objects
            mappedobject[key] = mapObjectToLabels(value, config)
          // } else if (Array.isArray(value)) {
            // Map arrays of objects (like people, units)
            // mappedobject[key] = value.map(item => mapObjectToLabels(item, config))
          } else {
            // Attempt to map the current key to a label if it exists in conf
            mappedobject[key] = getLabel(key, value)
          }
        }
      })
      return mappedobject
    }

    const mappedCrashes = crashes.map(crash => mapObjectToLabels(crash, configOptions))
    console.log('CO', configOptions, mappedCrashes)

    const preparedRows = mappedCrashes.map(crash => ({
      ...crash.road
      , ...crash.crash
      , ...crash.unit
      , ...crash.unit.people
      , ...crash.people
      , ...crash
    }))

    setRows(preparedRows)

    let columns = []
    let columnGroupingModel = []

    Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)
      .forEach(section => {
        let children = []
        configuration[section].elements.forEach(elem => {
          columns = [
            ...columns
            , {
              label      : elem.label
              , minWidth : 150
            }
          ]
          children = [...children, elem.label]
        })
        // columnGroupingModel = [...columnGroupingModel, { section, children }]
        columnGroupingModel = { ...columnGroupingModel, [section]: children }
      })

    console.log('GM', columnGroupingModel, 'COLS', columns)
    setColumns(columns)
    setColumnGroupingModel(columnGroupingModel)

    setFormModel(configuration)
    const initForms = Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)

    setFormList(initForms)
  }, [init, crashes])

  const getGraphs = useCallback(() => {
    // 1 collisions by severity data
    // 2 road traffic fatalities per year data
    // 3 road traffic fatalities by gender data
    // 4 Fatalities and serious injuries by road user type data
    // 5 number of collisions by day of the week data
    // 6 Road functional class collision data
    // 7 Type of collision data
    const statsUrl = new URL(statsBaseUrl)
    statsUrl.searchParams.set('type', 1)

    charts
      .map(chart => chart.apiNum)
      .forEach(async (graph, i) => {
        statsUrl.searchParams.set('type', i + 1)
        sendRequest({
          token
          , url           : statsUrl
          , manageResData : res => {
            console.log('STATS', i + 1, res)
            setCharts(old => old.map(el => el.apiNum === res.type ? ({ ...el, data: res.data }) : el))
          }
        })
      })
  }, [sendRequest, token])

  useEffect(() => {
    getGraphs()
  }, [getGraphs])

  const subPanelElements = [
    {
      text       : t`Logout`
      , icon     : <LogoutSharpIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : logout
    }
  ]

  const postFilterObj = data => {
    const body = {}
    const options = {
      token
      , url           : '#'
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        console.log('OPTS', res)
      }
    }
    sendRequest(options)
  }

  function FilterPanel ({ title, children }) {
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = () => {
      setExpanded(!expanded)
    }

    return (
    <Card sx={{ minWidth: '50%' }}>
      <CardHeader
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={title}
        sx={{ bgcolor: 'primary' }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
      </Collapse>
    </Card>
    )
  }

  return (
    <Box component="main">
      <DesktopNav subPanelElements={subPanelElements} />
      <Container component="main" maxWidth={'xl'}>
        <CssBaseline />
        <Box
          ml={`${drawerWidth}px`}
          mt={2}
          display='flex'
          flexDirection='column'
          gap={2}
          alignItems='center'
        >
          <FormProvider {...methods} {...user}>
          {formList.map((item, formNumber) => (
            <FilterPanel key={item} title={formModel[formList[formNumber]].displayName}>
              <FilterForm
                isMobile={isMobile}
                formModel={formModel}
                submitRef={submitRef}
                formList={formList}
                form={{
                  number        : formNumber
                  , type        : formList[formNumber]
                  , displayName : formModel[formList[formNumber]].displayName
                }}
              />
            </FilterPanel>
          ))}
          </FormProvider>
        </Box>
        <Box
          ml={`${drawerWidth}px`}
          mt={2}
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            '& .overload-header': {
              backgroundColor   : theme.palette.primary.main
              , color           : theme.palette.primary.contrastText
            }
          }}
        >
          <Typography sx={{ mt: 2, mb: 2 }} variant="h3" align="center">
            <Trans>Crash list</Trans>
          </Typography>
          {/* <Divider flexItem /> */}
          {/* <Divider flexItem sx={{ ml: '-50vw', width: '150vw' }}/> */}
          <Box width='150%' maxWidth='2970px'>
            {isLoading
              ? <TableSkel />
              : <>
                  <Typography textAlign='right'>
                    <Trans>Crashes found</Trans>: {totalCrashes.total}
                  </Typography>
                  <Paper sx={{ width: '100%' }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {Object.keys(columnGroupingModel).map(key => (
                            <StyledTableCell colSpan={columnGroupingModel[key].length}>
                              {key}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          {columns.map(column => (
                            <StyledTableCell
                              key={column.label}
                              // align={column.align}
                              style={{ top: 57, minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            console.log('ROW:', index, row)
                            // const isFirstCrashRow = index === 0 || rows[index - 1]['Crash id'] !== row['Crash id']
                            const isFirstCrashRow = index === 0 || row.crashSpan !== 0
                            // const isFirstUnitRow = index === 0 || rows[index - 1].unit['Unit id'] !== row.unit['Unit id']
                            const isFirstUnitRow = index === 0 || row.unitSpan

                            const getIsColInSection = (section, column) => {
                              return columnGroupingModel[section].includes(column.label)
                            }

                            return (
                              <StyledTableRow key={row.id}>
                                {columns
                                  .map(column => {
                                    const value = row[column.label]
                                    if (getIsColInSection('crash', column) && isFirstCrashRow) {
                                      return (
                                        <TableCell rowSpan={row.crashSpan} key={column.label} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    }
                                    if (getIsColInSection('road', column) && isFirstCrashRow) {
                                      return (
                                      <TableCell rowSpan={row.crashSpan} key={column.label} align={column.align}>
                                        {value}
                                      </TableCell>
                                      )
                                    }
                                    if (getIsColInSection('units', column) && isFirstUnitRow) {
                                      return (
                                        <TableCell rowSpan={row.unitSpan} key={column.label} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    }
                                    if (getIsColInSection('people', column) && isFirstUnitRow) {
                                      return (
                                        <TableCell rowSpan={row.unitSpan} key={column.label} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    }
                                    return (
                                      <TableCell key={column.label}></TableCell>
                                    )
                                  })}
                              </StyledTableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows
                      .filter((row, index) => index === 0 || rows[index - 1]['Crash id'] !== row['Crash id'])
                      .length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </>
              }
          </Box>
          {error && <Alert sx={{ mt: 2 }} severity="error">
            <Trans>There was an error: {error}.</Trans>
          </Alert>}
          {message && <Alert sx={{ mt: 2 }} severity='success'>
            {message}
          </Alert>}
        </Box>
        <Divider flexItem sx={{ ml: '-50vw', width: '150vw', mt: 2, mb: 2 }}/>
        <Box
          ml={`${drawerWidth}px`}
          mt={2}
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            '& .overload-header': {
              backgroundColor   : theme.palette.primary.main
              , color           : theme.palette.primary.contrastText
            }
          }}
        >
          {charts
            .map((graph, index) => {
              // series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
              if (index % 2 === 0) {
                return (
                  <Box key={graph.label} display='flex'>
                    <TextField
                      select
                      value={charts[index].type}
                      onChange={event => setCharts(old => old.map(el => el.apiNum === index + 1
                        ? ({ ...el, type: event.target.value })
                        : el
                      ))}
                      label="series type"
                      sx={{ minWidth: 150 }}
                    >
                      <MenuItem value="line"><Trans>line</Trans></MenuItem>
                      <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
                      <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
                    </TextField>
                      <ResponsiveChartContainer
                        series={
                          charts[index].data.map(el => ({ data: [el.value] }))
                        }
                        xAxis={[
                          {
                            data        : charts[index].data.map(el => el.label)
                            , scaleType : 'band'
                          }
                        ]}
                      >
                        <BarPlot />
                        <LinePlot />
                        <ChartsXAxis label="X axis" position="bottom" axisId="x-axis-id" />
                      </ResponsiveChartContainer>
                    {charts[index + 1] && <>
                      <TextField
                        select
                        value={charts[index + 1].type}
                        onChange={event => setCharts(old => old.map(el => el.apiNum === index + 2
                          ? ({ ...el, type: event.target.value })
                          : el
                        ))}
                        label="series type"
                        sx={{ minWidth: 150 }}
                      >
                        <MenuItem value="line"><Trans>line</Trans></MenuItem>
                        <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
                        <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
                      </TextField>
                      <div>
                        <ResponsiveChartContainer
                          series={
                            charts[index + 1].data.map(el => ({ data: [el.value] }))
                          }
                          xAxis={[
                            {
                              data        : charts[index + 1].data.map(el => el.label)
                              , scaleType : 'band'
                              , scale     : 'band'
                            }
                          ]}
                        >
                          <BarPlot />
                          <LinePlot />
                          <ChartsXAxis label="X axis" position="bottom" axisId="x-axis-id" />
                        </ResponsiveChartContainer>
                        </div>
                      </>
                    }
                  </Box >
                )
              }
              return null
            })
          }
        </Box>
      </Container>
    </Box>
  )
}

// {sample.map(item => (
//             <Fragment>
//               <TableRow>
//                 <TableCell rowSpan={item.detail.length + 1}>
//                   {item.name}
//                 </TableCell>
//               </TableRow>
//               {item.detail.map(detail => (
//                 <TableRow>
//                   <TableCell>{detail}</TableCell>
//                 </TableRow>
//               ))}
//             </Fragment>
//           ))}

//   return row.units.map((unit, unitIndex) => {
//     const totalPeople = unit.people.length

//     return unit.people.map((person, personIndex) => (
//     <StyledTableRow key={`${index}-${unitIndex}-${person.id}`}>
//       {columns.map((column, colIndex) => {
//         const getIsColInSection = (section, column) => {
//           return columnGroupingModel
//             .find(cg => cg.section === section).children.includes(column.label)
//         }

//         // Render Crash data once for the first row of each crash
//         if (getIsColInSection('crash', column) && personIndex === 0) {
//           return <TableCell rowSpan={totalRows} key={colIndex}>{row[column.label]}</TableCell>
//         }

//         // Render Road data once for the first row of each crash
//         if (getIsColInSection('road', column) && personIndex === 0 && unitIndex === 0) {
//           return <TableCell rowSpan={totalRows} key={colIndex}>{row[column.label]}</TableCell>
//         }

//         // Render Unit type data once for the first person in each unit
//         if (getIsColInSection('units', column) && personIndex === 0) {
//           return <TableCell rowSpan={totalPeople} key={colIndex}>{row[column.label]}</TableCell>
//         }

//         // Render Person ID and Gender (no rowSpan for people)
//         if (getIsColInSection('people', column)) {
//           return <td key={colIndex}>{row[column.label]}</td>
//         }

//         return null
//       })}
//     </StyledTableRow>
//     ))
//   })
// })}
// console.log(row, columnGroupingModel)
// let subRows = []
// if (row.units.length && !row.people.length) {
//   subRows = row.units
// } else subRows = row.people

// useEffect(() => {
//   let confSections = {}
//   Object.keys(configuration)
//     .forEach(key => {
//       confSections = {
//         ...confSections
//         , [key]: configuration[key].elements
//           .map((element, i) => ({ ...element, id: i + 1, section: key }))
//       }
//     })
//   console.log('CONF', confSections)
//   setRows(confSections.crash)
// }, [configuration])

{ /* {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value} */ }
// // const totalUnits = row.units.length
// // const totalRows = row.units.reduce((acc, unit) => acc + unit.people.length, 0) // Total rows for each crash

// return (
//   // <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
//   <>
//   <StyledTableRow key={row.id}>
//     {columns
//       // .filter(column => {
//       // //   // const columnList = columnGroupingModel
//       // //   //   .filter(col => col.section !== 'crash' && col.section !== 'road')
//       // //   //   .flatMap(col => col.children)
//       //   console.log(columnGroupingModel)
//       //   return columnGroupingModel.crash.includes(column.label) ||
//       //     columnGroupingModel.road.includes(column.label)
//       // })
//       .map(column => {
//         console.log(columns)
//         const value = row[column.label]
//         return (
//         <TableCell rowSpan={row.rowSpan} key={column.id} align={column.align}>
//           {/* {column.format && typeof value === 'number'
//             ? column.format(value)
//             : value} */}
//           {value}
//         </TableCell>
//         )
//       })}
//   </StyledTableRow>
{ /* {row.units.map((unit, i) => (
                               <StyledTableRow key={`${row.id}-unit-${i}`}>
                                {columns.map(column => {
                                  const value = unit[column.label]
                                  return (
                                    <TableCell key={`${column.label}-unit-${i}`}>
                                      {value}
                                    </TableCell>
                                  )
                                })}
                               </StyledTableRow>
                              ))} */ }
// </>)

//     : <DataGrid
//         showColumnVerticalBorder={true}
//         unstable_rowSpanning={true}
//         rows={rows}
//         columns={columns}
//         editMode="row"
//         columnGroupingModel={columnGroupingModel}
//         initialState={{
//           pagination   : { paginationModel }
//           , columns    : { columnVisibilityModel: { id: false } }
//         }}
//         pageSizeOptions={[10, 25, 50]}
//         sx={{
//           mt                              : 4
//           , border                        : 0
//           , '& .MuiDataGrid-menuIcon > *' : { color: theme.palette.primary.contrastText }
//         }}
//         slots={{
//           toolbar: TopToolbar
//         }}
//         slotProps={{
//           toolbar: { totalCrashes: totalCrashes.total }
//         }}
//       />
