import { useState, useCallback } from 'react'
import useLogout from './useLogout'
import { Trans, msg } from '@lingui/macro'

const codes = {
  400 : 'Bad Request'
  , 401 : 'Unauthorized'
  , 404 : 'Not Found'
  , 405 : 'Method Not Allowed'
  , 500 : 'Internal error'
}

const errors = [
  {}
]

const useHttp = () => {
  const logout = useLogout()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const reset = () => {
    setError(null)
    setIsLoading(false)
  }

  const sendRequest = useCallback(
    async ({ token, url, httpMethod, httpHeaders, httpBody, manageResData }) => {
      setIsLoading(true)
      setError(null)

      const defaultHeaders = {
        'content-type' : 'application/json'
        , accept         : 'application/json'
      }

      const headers = httpHeaders || (token
        ? { ...defaultHeaders, authorization: `Bearer ${token}` }
        : defaultHeaders)

      try {
        const response = await fetch(url, {
          method : httpMethod || 'GET'
          , headers
          , body   : JSON.stringify(httpBody) || null
          // credentials: 'include'
        })

        if (!response.ok) {
          const text = await response.text() // This reads from body
          const status = response.status
          const statusText = response.statusText
          console.error('ERROR', text, statusText, url)
          if (status === 401) logout()
          throw new Error(`${codes[status]}${text && ' '}${text}.`)
        }

        const json = await response.json()

        if (json.result !== 'success') {
          throw new Error(json.error)
        }

        return manageResData(json)
      } catch (error) {
        console.error('THROWN ERROR', error, url)
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  return { isLoading, error, sendRequest, reset }
}

export default useHttp
