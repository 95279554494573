import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Trans } from '@lingui/macro'
import {
  Badge
  , Box
  , Button
  , CircularProgress
  , Dialog
  , DialogActions
  , DialogContent
  , DialogContentText
  , DialogTitle
  , Drawer as MuiDrawer
  , IconButton
  , styled
  , Typography
  , useTheme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import SaveIcon from '@mui/icons-material/Save'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import PersonIcon from '@mui/icons-material/Person'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EditRoadSharpIcon from '@mui/icons-material/EditRoadSharp'
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
// import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
// import InfoIcon from '@mui/icons-material/Info'
// import EditRoadIcon from '@mui/icons-material/EditRoad'
// import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined'
import Form from './Form'

const AddIcon = ({ variant }) => (
  <>
    {variant === 'person' && <PersonIcon />}
    {variant === 'unit' && <DirectionsCarIcon />}
    <Typography variant='h6' fontWeight={700}>+</Typography>
  </>
)

export default function FormContainer ({
  token
  , open
  , isMobile
  , isLoading
  , formList
  , formModel
  , form
  , submitRef
  , handleAppend
  , handleFormOpen
  , handleSetFormNumber
  , handleCrashSubmit
  , handlePositioningState
}) {
  const location = useLocation()
  const { init } = location.state
  const { control, setValue, formState, getValues, reset, user } = useFormContext()
  const theme = useTheme()
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const toolbarHeight = theme.mixins.toolbar.minHeight
  const [roadBadge, setRoadBadge] = useState(false)
  const [unitsBadge, setUnitsBadge] = useState(0)
  const [peopleBadge, setPeopleBadge] = useState(0)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)
  const [unitFormList, setUnitFormList] = useState([])
  const [lastUnitFormIndex, setlastUnitFormIndex] = useState(0)

  console.log('F CONT', formList, form, formModel, init.user, user)

  useEffect(() => { // create a list of units forms
    const unitFormList = formList.filter(form => /^units\.\d+$/.test(form))
    if (unitFormList.length) setUnitFormList(unitFormList)
    const currentUnitFormListIndex = unitFormList.indexOf(form.name)
    if (currentUnitFormListIndex !== -1) setlastUnitFormIndex(currentUnitFormListIndex)
  }, [formList, form.name])

  const { append } = useFieldArray({
    control
    , name: form.type === 'units' || form.type === 'people'
      ? `${(formList[form.number] || []).split('.').slice(0, 2).join('.')}.people`
      : form.type
  })

  const openDialog = () => { setIsSaveDialogOpen(true) }

  const closeDialog = () => { setIsSaveDialogOpen(false) }

  const getFormCtxUserOrg = () => {
    if (init.user.type === 1 && Object.prototype.hasOwnProperty.call(user, 'doAsOrganization')) {
      return user.doAsOrganization
    }
    return init.user.organization
  }

  // children = Children.map(children, el => cloneElement(el, { submitRef, handleSubmit }))

  useEffect(() => {
    console.log('FORM STATE', formState)
    console.log('FORM VALUES', getValues())
    const form = getValues()
    if (Object.prototype.hasOwnProperty.call(form, 'units')) {
      setUnitsBadge(form.units.length)
      setRoadBadge(true)
      setPeopleBadge(form.units.map(unit => unit.people?.length).reduce((a, c) => a + c, 0))
    }
    if (Object.prototype.hasOwnProperty.call(form, 'people')) {
      setPeopleBadge(form.people.length)
    }
  }, [formState, getValues])

  let Drawer
  if (isMobile) {
    Drawer = styled(MuiDrawer)({
      '& .MuiDrawer-paper': { height: `calc(100% - ${toolbarHeight}px)` }
    })
  } else {
    Drawer = styled(MuiDrawer)({
      position               : 'relative' // imp
      , width                : formDrawerWidth
      , '& .MuiDrawer-paper' : {
        width        : formDrawerWidth
        , height     : '100vh'
        , position   : 'absolute' // imp
        , transition : 'none !important'
      }
    })
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor={isMobile ? 'bottom' : undefined}
    >
      <Box display='flex' flexDirection='column' p={1}>
        <Typography width='100%' variant='button' fontWeight={700} textAlign='center'>
          <Trans>NEW CRASH RECORD</Trans>
        </Typography>
        <Typography textTransform='uppercase' width='100%' variant='h6' fontWeight={600} textAlign='center'>
          {`${form.displayName}${form.type === 'units' ? ' ' + (lastUnitFormIndex + 1) : ''}`}
        </Typography>
        <Box mt={1} display='flex' justifyContent='space-evenly' alignItems='center' width='100%'>
          <Badge
            color='success'
            overlap='circular'
            variant='dot'
            invisible={formState.submitCount === 0}
          >
            <IconButton
              sx={form.type === 'crash' ? { backgroundColor: '#ebebeb' } : {}}
              onClick={() => {
                if (form.type !== 'crash') {
                  submitRef.current.requestSubmit()
                  handleSetFormNumber(formList.indexOf('crash'))
                }
              }}
            >
              <InfoOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large' } />
            </IconButton>
          </Badge>
          {Object.prototype.hasOwnProperty.call(formModel, 'road') &&
            <Badge
            color='success'
            overlap='circular'
            variant='dot'
            invisible={!roadBadge}
          >
              <IconButton
                sx={form.type === 'road' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => {
                  if (form.type !== 'road') {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber(formList.indexOf('road'))
                  }
                }}
              >
              <EditRoadSharpIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
            </IconButton>
          </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'units') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={unitsBadge}
            >
              <IconButton
                sx={form.type === 'units' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => {
                  submitRef.current.requestSubmit()
                  let nextUnitForm
                  const currentIndex = unitFormList.indexOf(form.name)
                  if (currentIndex !== -1) {
                    if (currentIndex + 1 < unitFormList.length) {
                      nextUnitForm = unitFormList[currentIndex + 1]
                    } else nextUnitForm = unitFormList[0]
                  } else {
                    nextUnitForm = unitFormList[lastUnitFormIndex]
                  }
                  handleSetFormNumber(formList.indexOf(nextUnitForm))
                }}
              >
                <DirectionsCarOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'people') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={peopleBadge}
            >
              <IconButton
                sx={form.type === 'people' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => console.log('Click!')}
              >
                <PersonOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          <IconButton onClick={() => {
            if (!isMobile) reset()
            handlePositioningState(false)
            handleFormOpen(false)
          }}>
            <CloseIcon color='primary' fontSize={isMobile ? 'medium' : 'large' } />
          </IconButton>
        </Box>
      </Box>
      <Box overflow='auto'>
        {/* {children} */}
        <Form
          token={token}
          isMobile={isMobile}
          submitRef={submitRef}
          formModel={formModel}
          formList={formList}
          form={form}
          // unitsFormArray={{ fields: unitsMethods.fields }}
          handleCrashSubmit={handleCrashSubmit}
          handleAppend={handleAppend}
          handleSave={''}
        />
        <Box display='flex' flexDirection='column' gap={1} mt={2} mb={2} pl={1} pr={1}>
          {isMobile && isLoading && <CircularProgress />}
          {form.type === 'crash' && getFormCtxUserOrg() === 2 &&
            <Button variant='contained' onClick={() => { handleAppend('people') }}>
              <AddIcon variant='person' />
            </Button>}
          {form.type === 'road' &&
            <Button variant='contained' onClick={() => { handleAppend('units') }}>
              <AddIcon variant='unit' />
            </Button>}
          {form.type === 'units' &&
            <>
              <Button variant='contained' onClick={() => { handleAppend('units') }}>
                <AddIcon variant='unit' />
              </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleAppend('unitPeople', formList[form.number], append)
              }}>
                <PersonIcon />
                <Typography variant='h6' fontWeight={700}>+</Typography>
              </Button>
            </>}
          {form.type === 'people' && getFormCtxUserOrg() === 1 &&
            <Button
              variant='contained'
              onClick={() => {
                handleAppend('unitPeople', formList[form.number], append)
              }}>
              <AddIcon variant='person' />
            </Button>}
          {form.type === 'people' && getFormCtxUserOrg() === 2 &&
            <Button
              variant='contained'
              onClick={() => {
                handleAppend('people', formList[form.number], append)
              }}>
              <AddIcon variant='person' />
            </Button>}
        </Box>
        <Box display='flex' justifyContent='space-evenly' mt={3} mb={2}>
          {isMobile && isLoading
            ? <CircularProgress />
            : <>
                <Button
                  variant='contained'
                  disabled={form.number === 0}
                  onClick={() => {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber('-')
                  }}
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                variant='contained'
                disabled={!getValues().units?.length}
                onClick={() => {
                  setValue('isFinal', true)
                  // submitRef.current.requestSubmit()
                  openDialog()
                }}
                >
                  <SaveIcon />
                </Button>
              <Button
                variant='contained'
                onClick={() => {
                  setValue('isDraft', true)
                  setValue('isFinal', true)
                  submitRef.current.requestSubmit()
                }}
              >
                  <SaveAsIcon />
                </Button>
                <Button
                  variant='contained'
                  disabled={form.number === formList.length - 1}
                  onClick={() => {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber('+')
                  }}
                >
                  <ArrowRightIcon />
                </Button>
              </>}
        </Box>
      </Box>
      <Dialog open={isSaveDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          <Trans>What do you want to do?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              You are saving a crash record permanently.
            </Trans>
            <br />
            <Trans>
              If you click on OK, only an admin can amend it hereafter.
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='error' onClick={closeDialog}>Cancel</Button>
          <Button variant='outlined' color='success' onClick={closeDialog}>Save as draft</Button>
          <Button
            variant='outlined'
            onClick={() => submitRef.current.requestSubmit()}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  )
}
