import { useState } from 'react'
import {
  Box
  , Drawer as MuiDrawer
  , FormControlLabel
  // , IconButton
  , List
  , ListItem
  , ListItemButton
  , ListItemIcon
  , ListItemText
  , styled
  , Switch
  , useTheme
} from '@mui/material'

export default function MobileLayersPanel ({
  open
  , subPanelElements
}) {
  const theme = useTheme()
  const toolbarHeight = theme.mixins.toolbar.minHeight
  const [state, setState] = useState(
    subPanelElements
      .filter(e => e.onMobile)
      .map(e => ({ [e.name]: false }))
  )

  const Drawer = styled(MuiDrawer)({
    width                : '100vw'
    , '& .MuiDrawer-paper' : {
      width      : '100vw'
      , height     : `calc(${toolbarHeight}px + 1rem  + 1rem * ${subPanelElements.length / 2})`
      , alignItems : 'center'
    }
  })

  const composeList = elem => {
    if (elem.type === 'switch') {
      return (
          <ListItem key={elem.text}>
            <FormControlLabel
              control={<Switch checked={state[elem.name]} name={elem.name} />}
              label={elem.text}
              onChange={e => {
                setState({ ...state, [e.target.name]: e.target.checked })
                return elem.action(e)
              }}
            />
          </ListItem>
      )
    }
    if (elem.type === 'button') {
      return (
        <ListItem disablePadding key={elem.text}>
          <ListItemButton onClick={elem.action}>
            <ListItemIcon>
              {elem.icon}
            </ListItemIcon>
            <ListItemText primary={elem.text} />
          </ListItemButton>
        </ListItem>
      )
    }
    return ''
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor='bottom'
    >
      <Box display='flex' justifyContent='space-evenly' flexWrap='wrap' overflow='auto'>
        <List dense disablePadding>
          {subPanelElements
            .filter(elem => elem.onMobile)
            .filter((_, index) => index % 2)
            .map(composeList)
          }
        </List>
        <List dense disablePadding>
          {subPanelElements
            .filter(elem => elem.onMobile)
            .filter((_, index) => !index % 2)
            .map(composeList)
          }
        </List>
      </Box>
    </Drawer>
  )
}
