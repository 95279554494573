import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { Trans, t, msg } from '@lingui/macro'
import {
  Alert
  , Box
  , Button
  , Container
  , CssBaseline
  , Grid
  , IconButton
  , InputAdornment
  , LinearProgress
  , Link
  , TextField
} from '@mui/material/'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import Copyright from '../components/Copyright'
import useHttp from '../hooks/useHttp'

// eslint-disable-next-line no-restricted-globals
const loginUrl = new URL('/api/login', location)
// eslint-disable-next-line no-restricted-globals
const initUrl = new URL('/api/init', location)
const flag = `url('assets/${process.env.REACT_APP_FLAG}')`

const organizations = [
  { value: 0, label: t`Admin`, icon: 'security' }
  , { value: 1, label: t`Police`, icon: 'localPolice' }
  , { value: 2, label: t`Healthcare`, icon: 'localHospital' }
  , { value: 3, label: t`Insurance`, icon: 'accountBalance' }
]

export default function Login ({ isMobile }) {
  const navigate = useNavigate()
  const [token, setToken] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const { isLoading, error, sendRequest, reset } = useHttp()

  const { _ } = useLingui()

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const username = data.get('username')
    const password = data.get('password')

    const authdata = window.btoa(`${username}:${password}`)

    sendRequest({
      url             : loginUrl
      , httpMethod    : 'POST'
      , httpHeaders   : { Authorization: `Basic ${authdata}` }
      , manageResData : res => { setToken(res.token) }
    })
  }

  useEffect(() => {
    if (token) {
      sendRequest({
        token
        , url           : initUrl
        , manageResData : res => {
          console.log('INIT', res)
          const { error: initError, error_code, result, ...init } = res // { configuration, defaults, user, totalCrashes }
          navigate('/home', { state: { token, init, organizations } })
        }
      })
    }
  }, [token, sendRequest, navigate])

  return (
    <Container component="main" maxWidth={isMobile ? 'xs' : 'sm'}>
      <CssBaseline />
      <Box
        mt={8}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          width='50%'
          mb='1rem'
          border='1px solid #c4c4c4'
          borderRadius={1}
          sx={{
            aspectRatio       : '3/2'
            , backgroundImage : flag
            , backgroundSize  : 'cover'
          }}>
        </Box>
        <Box width='50%'>
          <img src='../assets/logo_adams.png' alt='ADaMS logo' width='100%' />
        </Box>
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          component='form'
          onSubmit={handleSubmit}
          noValidate
          mt='2rem'
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={_(msg`Username`)}
            name="username"
            autoComplete="username"
            autoFocus
            error={error}
            onChange={() => error && reset()}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={_(msg`Password`)}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            error={error}
            onChange={() => error && reset()}
            InputProps={{
              endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
          />
          {isLoading && <LinearProgress sx={{ mt: 2 }}/>}
          {error && <Alert sx={{ mt: 2 }} severity="error">
            {
              error === 'Unauthorized'
                ? <Trans>Wrong username or password</Trans>
                : error
            }
          </Alert>}
          <Button
            type="submit"
            fullWidth={isMobile}
            variant="contained"
            color='primary'
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Login</Trans>
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                <Trans>Forgot password?</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                <Trans>Sign Up</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={3} mb={2} display='flex' justifyContent='space-evenly' gap={2}>
        {/* <Box display='flex' alignItems='center' justifyContent='center' bgcolor='#4a4a4a' borderRadius={5} width={100} height={100} sx={{ transform: 'rotate(45deg)' }}> */}
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='../assets/logo_fred.png' alt='Fred logo' width='90%' />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='#' alt="Sponsor" width='90%' height='90%' style={{ border: '1px solid #c4c4c4' }}/>
        </Box>
      </Box>
      <Box mt={4} display='flex' justifyContent='center'>
        <Copyright />
      </Box>
    </Container>
  )
}
