import { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import {
  Alert
  , Box
  , Container
  , CssBaseline
  , Divider
  , Paper, Skeleton
  , Typography
  , useTheme
} from '@mui/material/'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import DesktopNav from '../components/desktop/DesktopNav'
import OrganizationIcon from '../components/OrganizationIcon'
import UsersTable from '../components/tables/UsersTable'
import ConfTable from '../components/tables/ConfigsTable'

// eslint-disable-next-line no-restricted-globals
const getUrl = new URL('/api/users', location)
// eslint-disable-next-line no-restricted-globals
const postUrl = new URL('/api/user', location)
// eslint-disable-next-line no-restricted-globals
const structUrl = new URL('/api/structure', location)
// eslint-disable-next-line no-restricted-globals
const initUrl = new URL('/api/init', location)

export default function Admin ({ isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = location.state?.init || {}
  const organizations = location.state?.organizations || {}
  const { configuration } = init
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest } = useHttp()
  const [users, setUsers] = useState([])
  const [config, setConfig] = useState({})
  const [message, setMessage] = useState({})

  const TableSkel = () => ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
  )

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state: { token } })
  }, [isMobile, navigate, token, logout])

  const getUsers = useCallback(() => {
    sendRequest({
      token
      , url           : getUrl
      , manageResData : res => {
        setUsers(res.users)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const modifyUser = data => {
    let { id, name, surname, organization, department, departmentAlt, verified } = data
    if (organization === 2) department = departmentAlt
    const body = { id, verified, name, surname, department }
    console.log('BODDDY', body)
    const options = {
      token
      , url           : postUrl
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          setMessage(old => ({ ...old, user: () => <Trans>User successfully modified!</Trans> }))
          getUsers()
        }
      }
    }
    console.log('OPTS', options)
    sendRequest(options)
  }

  useEffect(() => {
    let config = {}
    Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)
      .forEach(key => {
        config = {
          ...config
          , [key]: configuration[key].elements
            .map((element, i) => ({ ...element, id: i + 1, section: key }))
        }
      })
    console.log('CONF', config)
    setConfig(config)
  }, [configuration])

  const subPanelElements = [
    {
      text       : t`Logout`
      , icon     : <LogoutSharpIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : logout
    }
  ]

  const modifyField = (label, data) => {
    const group = data.section
    const body = { action: 'update', group, data, label }
    console.log('BODDDY', body)
    const options = {
      token
      , url           : structUrl
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          // setMessage(old => ({ ...old, [group]: () => <Trans>Field successfully modified!</Trans> }))
          sendRequest({
            token
            , url           : initUrl
            , manageResData : res => {
              console.log('INIT', res)
              const { error: initError, error_code, result, ...init } = res // { configuration, defaults, user, totalCrashes }
              navigate('.', { state: { token, init, organizations } })
              setMessage(old => ({ ...old, [group]: () => <Trans>Field successfully modified!</Trans> }))
            }
          })
        }
      }
    }
    console.log('OPTS', options)
    sendRequest(options)
  }

  return (
    <Box component="main">
      <DesktopNav subPanelElements={subPanelElements} />
      <Container component="main" maxWidth={'xl'}>
        <CssBaseline />
        <Box
          ml={`${drawerWidth}px`}
          mt={4}
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={3}
          sx={{
            '& .overload-header': {
              backgroundColor   : theme.palette.primary.main
              , color           : theme.palette.primary.contrastText
            }
          }}
        >
          {isLoading
            ? <TableSkel />
            : <Paper sx={{ width: '100%' }}>
                <UsersTable usersRows={users} modifyUser={modifyUser} error={error} />
              </Paper>}
          {message.user && <Alert sx={{ mt: 2 }} severity='success'>
            {message.user}
          </Alert>}
          {error && <Alert sx={{ mt: 2 }} severity="error">
            <Trans>There was an error: {error}.</Trans>
          </Alert>}
          <Divider flexItem sx={{ ml: '-50vw', width: '150vw' }}/>
          <Typography sx={{ mt: 2, mb: 2 }} variant="h3" align="center">
            <Trans>Fields configuration</Trans>
          </Typography>
          <Divider flexItem sx={{ ml: '-50vw', width: '150vw' }}/>
          <Box mt={3} display='flex' justifyContent='space-evenly' width='100%'>
            {organizations
              .filter(org => org.value !== 0)
              .map(org => (
                <Box key={org.value} display='flex'>
                  <OrganizationIcon organization={org.label} />
                  <Typography ml={0.5}>{`= ${org.label}`}</Typography>
                </Box>
              ))}
          </Box>
            {Object.keys(config).map((section, i) => (
              <Fragment key={section}>
                {isLoading
                  ? <TableSkel />
                  : <Paper sx={{ width: '100%', mb: i === Object.keys(config).length - 1 ? 4 : 0 }}>
                      <ConfTable
                      section={section}
                      sectionRows={config[section]}
                      modifyField={modifyField}
                      message={message[section]}
                      error={error}
                      />
                  </Paper>}
              </Fragment>
            ))}
        </Box>
      </Container>
    </Box>
  )
}
