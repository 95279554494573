import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { msg, Trans } from '@lingui/macro'
import {
  Alert
  , Box
  , Button
  , Divider
  , TextField
  , Typography
  , styled, useTheme
} from '@mui/material/'
import {
  GridRowModes
  , DataGrid
  , GridToolbarContainer
  , GridActionsCellItem
  , GridRowEditStopReasons
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import { randStr } from '../../utils'
import OrganizationIcon from '../OrganizationIcon'

// canChange: false
// column: "report_date"
// isRequired: true
// label: "Report date"
// margin: "dense"
// options: []
// permissions: [1, 2, 3]
// type: "date"
// unit: ""
// value: {}

const paginationModel = { page: 0, pageSize: 25 }

function EditToolbar ({ section, setRows, setRowModesModel }) {
  const handleClick = () => {
    const id = randStr()
    setRows(oldConfig => [
      {
        id
        , section
        , organizations : []
        , label         : ''
        , isRequired    : false
        , type          : 'text'
        , options       : []
        , unit          : ''
        , value         : ''
        , isNew         : true
      }
      , ...oldConfig
    ])
    setRowModesModel(oldModel => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'permissions' }
      , ...oldModel
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        <Trans>Add item</Trans>
      </Button>
    </GridToolbarContainer>
  )
}

export default function ConfTable ({ section, sectionRows, modifyField, error, message }) {
  const location = useLocation()
  const init = location.state?.init || {}
  const organizations = location.state?.organizations || {}
  const { configuration } = init
  const theme = useTheme()
  const { _ } = useLingui()
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})

  useEffect(() => {
    if (sectionRows.length) setRows(sectionRows)
  }, [sectionRows])

  useEffect(() => {
    console.log('ROWS', rows)
  }, [rows])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // const handleDeleteClick = id => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel
      , [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false }
    console.log('UPD', updatedRow, 'OLD', oldRow)
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    modifyField(oldRow.label, updatedRow)
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    { field: 'id', headerName: '' } // hidden
    , { field: 'section', headerName: 'Section' } // hidden
    , { field: 'column', headerName: 'Column' } // hidden
    , { field: 'isNew', headerName: '', type: 'boolean' } // hidden
    , {
      field           : 'permissions'
      , headerName      : 'Organizations'
      , headerClassName : 'overload-header'
      , flex            : 1.1 // , minWidth: 110
      , editable        : (_, row) => row.isNew
      , valueGetter     : value => value?.map(v => organizations.find(o => o.value === v).label)
      , valueSetter     : (value, row) => {
        if (value) {
          let matches = []

          if (Array.isArray(value)) matches = value
          else {
            const regex = /\b(police|healthcare|insurance)\b/gi
            let match

            while ((match = regex.exec(value)) !== null) {
              matches = [...matches, match[0]]
            }
          }

          const newValue = matches.map(v => organizations.find(o => o.label === v).value)
          return { ...row, organizations: newValue }
        }
        return {}
      }
      , renderCell: params => {
        if (params.value?.length) {
          return (
            <>
              {params.value.map(v => <OrganizationIcon key={randStr()} organization={v} style={{ marginTop: '0.5em' }} />)}
            </>
          )
        }
      }
    }
    , {
      field             : 'label'
      , headerName      : 'Label'
      , headerClassName : 'overload-header'
      , flex            : 2 // , minWidth: 200
      , editable        : (_, row) => row.canChange
      , renderEditCell  : ({ value }) => {
        if (value === '') {
          return <TextField sx={{ border: null, width: '100%', '&:focus-visible': { border: 0 } }} placeholder='Type a label...' />
        }
        return value
      }
    }
    , {
      field             : 'isRequired'
      , headerName      : 'Required?'
      , headerClassName : 'overload-header'
      , type            : 'boolean'
      , flex            : 1 // , minWidth: 100
      , editable        : (_, row) => row.canChange
    }
    , {
      field             : 'type'
      , headerName      : 'Type'
      , headerClassName : 'overload-header'
      , type            : 'singleSelect'
      , valueOptions    : ['text', 'select', 'date', 'datetime']
      , flex            : 1 // , minWidth: 100
      , editable        : (_, row) => row.isNew

    }
    , {
      field             : 'options'
      , headerName      : 'Options'
      , headerClassName : 'overload-header'
      , flex            : 4 // , minWidth: 400
      , editable        : (_, row) => row.canChange
      , valueGetter     : (value, row) => row.type === 'select' ? value.map(e => e.label) : []
      , valueSetter     : (value, row, ctx) => {
        if (row.type === 'select') {
          let newOptions
          if (rows.some(row => row.isNew)) {
            const input = Array.isArray(value) ? value : value.split(',')
            newOptions = input.map((v, i) => ({ label: v, value: i }))
          } else {
            const options = configuration[row.section].elements
              .find(elem => elem.column === row.column).options
            console.log(options)
            const input = Array.isArray(value) ? value : value.split(',')
            newOptions = input.map(v => ({ label: v, value: options.find(o => o.label === v).value }))
            // newOptions = input.map((v, i) => ({ label: v, value: i }))
          }
          return { ...row, options: newOptions }
        }
        return []
      }
      // , renderEditCell : params =>
    }
    , {
      field             : 'unit'
      , headerName      : 'Unit'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth        : 100
      , editable        : (_, row) => row.canChange
    }
    , {
      field             : 'value'
      , headerName      : 'Default'
      , headerClassName : 'overload-header'
      , flex            : 1 // , minWidth        : 100
      , editable        : (_, row) => row.isNew
      , valueGetter     : (value, row) => {
        if (row.type === 'date') return _(msg`date of fill`)
        if (row.type === 'datetime') return _(msg`date & time of fill`)
        if (row.type === 'text') return _(msg`empty field`)
        if (row.type === 'select') return _(msg`Unknown`)
        return value
      }
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : 'Actions'
      , headerClassName : 'overload-header'
      , cellClassName   : 'actions'
      , flex            : 1 // , minWidth        : 100
      , getActions      : obj => {
        const { id, row: { canChange } } = obj
        const isInEditMode = () => {
          return rowModesModel[id]?.mode === GridRowModes.Edit
        }

        if (isInEditMode()) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={!canChange}
          />
        ]
      }
    }
  ]

  return (
    <>
      <Typography textTransform='uppercase' sx={{ mt: 2 }} variant="h4" align="center">
        <Trans>{section}</Trans>
      </Typography>
      {/* <Divider flexItem /> */}
      <Box
        width='100%'
        sx={{
          '& .overload-header': {
            backgroundColor   : theme.palette.primary.main
            , color           : theme.palette.primary.contrastText
          }
        }}
      >
        <DataGrid
          showColumnVerticalBorder={true}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={() => handleRowModesModelChange('crash')}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination   : { paginationModel }
            , columns    : {
              columnVisibilityModel: {
                id        : false
                , section : false
                , column  : false
                , isNew   : false
              }
            }
          }}
          pageSizeOptions={[paginationModel.pageSize]}
          sx={{
            mt                              : 1
            , border                        : 0
            , '& .MuiDataGrid-menuIcon > *' : { color: theme.palette.primary.contrastText }
            , '& .MuiTablePagination-root'  : { display: rows.length > paginationModel.pageSize ? 'block' : 'none' }
          }}
          slots={{
            toolbar: EditToolbar
          }}
          slotProps={{
            toolbar: { section, setRows, setRowModesModel }
          }}
        />
        {error && <Alert sx={{ mt: 2 }} severity="error">
          <Trans>There was an error: {error}.</Trans>
        </Alert>}
        {message && <Alert sx={{ mt: 2 }} severity='success'>
          {message()}
        </Alert>}
      </Box>
    </>
  )
}
