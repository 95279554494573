import { createContext, useContext, useState } from 'react'

const InitContext = createContext()

export const InitProvider = ({ children }) => {
  const [init, setInit] = useState({})

  return (
    <InitContext.Provider value={{ init, setInit }}>
      {children}
    </InitContext.Provider>
  )
}

export const useInit = () => {
  const context = useContext(InitContext)
  if (!context) {
    throw new Error('useInit must be used within a InitProvider')
  }
  return context
}
