import { useCallback, useEffect } from 'react'
import {
  Box
  , Divider
  , FormControl
  , InputAdornment
  , InputLabel
  , ListSubheader
  , MenuItem
  , Select
  , TextField
  , Tooltip
  , Typography
} from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, DatePicker, MobileDatePicker } from '@mui/x-date-pickers/'
import { useFormContext, Controller } from 'react-hook-form'
import moment from 'moment'

const DynamicForm = ({ isMobile, formModel, form, formList }) => {
  const { control } = useFormContext()

  return (
    <>
      {formModel[form.type].elements
        .filter(elem => (elem.type !== 'coordinates' &&
          elem.label !== 'Crash id' &&
          elem.label !== 'Report date' &&
          elem.label !== 'Crash date'
        ))
        .map(elem => {
          if (elem.type === 'datetime') {
            return (
              <FormControl key={`${form.type}-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          viewRenderers={{
                            hours   : renderTimeViewClock
                            , minutes : renderTimeViewClock
                            , seconds : renderTimeViewClock
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          if (elem.type === 'date') {
            return (
              <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          /* it could need to be unregistered */
          // if (elem.type === 'divider') {
          //   return (<Divider key={`${form.type}-${elem.type}`} aria-hidden='true' sx={{ margin: '1rem 0' }} />)
          // }

          if (elem.type === 'select') {
            return (
            <FormControl key={`${elem.type}-${elem.label}`} margin='normal'>
              <InputLabel id={`select-${elem.label}`}>{elem.label}</InputLabel>
              <Controller
                name={`${formList[form.number]}.${elem.label}`}
                control={control}
                render={({ field }) => (
                  <Tooltip
                    placement='right'
                    title='ALL TOOLLTIOOPPPPPSS'
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]:
                            {
                              marginLeft: 4
                            }
                        }
                      }
                    }}
                  >
                    <Select
                      {...field}
                      labelId={`select-${elem.label}`}
                      id={`select-${elem.label}`}
                      label={elem.label}
                      value={field.value}
                      onChange={field.onChange}
                      startAdornment={elem.prefix && <InputAdornment>{elem.prefix}&nbsp;</InputAdornment>}
                    >
                      {elem.options.map(opt => {
                        if (field.name.includes('Nationality') || field.name.includes('country')) {
                          return (
                            <MenuItem key={opt.label} value={opt.value}>
                              <Box display='flex'>
                                {opt.value !== 0 && <Box
                                  height='1.5rem'
                                  border='1px solid #c4c4c4'
                                  borderRadius={1}
                                  sx={{
                                    aspectRatio        : '4/3'
                                    , backgroundImage    : `url('${opt.flag}')`
                                    , backgroundSize     : 'cover'
                                    , backgroundPosition : 'center center'
                                  }}
                                />}
                                <Typography ml={opt.value !== 0 ? 3 : 0}>{opt.label}</Typography>
                              </Box>
                            </MenuItem>
                          )
                        } else {
                          return opt.value && opt.value % 100 === 0
                            ? <ListSubheader key={`${opt.label}-sub`}>{opt.label}</ListSubheader>
                            : <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
                        }
                      })}
                    </Select>
                  </Tooltip>
                )}
              />
            </FormControl>
            )
          }

          return (
          <FormControl key={`${elem.type}-${elem.label}`}>
            <Controller
              name={`${formList[form.number]}.${elem.label}`}
              control={control}
              render={({ field }) => (
                <TextField
                  margin={elem.margin || 'normal'}
                  fullWidth
                  required={elem.isRequired}
                  id={elem.label}
                  label={elem.label}
                  value={field.value}
                  onChange={field.onChange}
                  {...field}
                />
              )}
            />
          </FormControl>
          )
        })}
    </>
  )
}

const Form = ({
  token
  , isMobile
  , submitRef
  , form
  , formModel
  , formList
  , formArray
  , handleCrashSubmit
  , isFilterForm
}) => {
  const { control, setValue, handleSubmit, watch } = useFormContext()
  const watchReportDate = watch('crash.Report date')
  const watchCrashDate = watch('crash.Crash date')

  console.log(formArray, form.type)

  const getNewId = useCallback(date => {
    // eslint-disable-next-line no-restricted-globals
    const newCrashIdUrl = new URL('/api/crash/new_crash_id', location)
    newCrashIdUrl.searchParams.set('date', moment(date).format('YYYY-MM-DD'))

    fetch(newCrashIdUrl, {
      headers: {
        Authorization  : `Bearer ${token}`
        , 'Content-Type' : 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        setValue('crash.Crash id', res.crash_id) // Set the value of Crash id
      })
  }, [token, setValue])

  useEffect(() => {
    if (!isFilterForm && form.type === 'crash') {
      if (!watchReportDate) setValue('crash.Report date', moment())
      if (!watchCrashDate) setValue('crash.Crash date', moment())
      getNewId(watchCrashDate)
    }
  }, [watchReportDate, watchCrashDate, isFilterForm, form.type, setValue, getNewId])

  return (
    <Box
      name={form.type}
      component="form"
      ref={submitRef}
      onSubmit={handleSubmit(handleCrashSubmit)}
      display='flex'
      flexDirection='column'
      pl={1}
      pr={1}
      noValidate
    >
      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Report date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name={'crash.Report date'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDatePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? moment(value)
                          : isFilterForm
                            ? null
                            : moment()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DatePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? moment(value)
                          : isFilterForm
                            ? null
                            : moment()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && <Divider key='crash-divider' aria-hidden='true' sx={{ margin: '1rem 0' }} />}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash id')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`}>
              <Controller
                name={'crash.Crash id'}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    margin={elem.margin || 'normal'}
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name={`crash.${elem.label}`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDateTimePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? moment(value)
                          : isFilterForm
                            ? null
                            : moment()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DateTimePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? moment(value)
                          : isFilterForm
                            ? null
                            : moment()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        viewRenderers={{
                          hours   : renderTimeViewClock
                          , minutes : renderTimeViewClock
                          , seconds : renderTimeViewClock
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.type === 'coordinates')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`}>
              <Controller
                name={`crash.${elem.label}`}
                control={control}
                render={({ field }) => (
                  <TextField
                    margin={elem.margin || 'normal'}
                    fullWidth
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

        <DynamicForm
          isMobile={isMobile}
          formModel={formModel}
          formList={formList}
          form={form}
          formArray={formArray}
        />
    </Box>
  )
}

export default Form
