import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { randStr } from '../../utils'
import {
  // Avatar,
  Badge
  , Box
  , Divider
  , Drawer
  , FormControlLabel
  , List
  , ListItem
  , ListItemButton
  , ListItemIcon
  , ListItemText
  , Switch
  , Toolbar
  , Typography, useTheme
} from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HelpIcon from '@mui/icons-material/Help'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'

import useLogout from '../../hooks/useLogout'
import OrganizationIcon from '../OrganizationIcon'

export default function DesktopNav ({ subPanelElements }) {
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const location = useLocation()
  const { state: { token, init, organizations } } = location
  const logout = useLogout()

  useEffect(() => {
    if (!token) logout()
  }, [logout, token])

  const styles = {
    navLink: {
      textDecoration   : 'none'
      , color          : theme.palette.text.primary
      , width          : '100%'
    }
  }

  const mainPanelElements = [
    {
      text     : <Trans>GIS view & Data entry</Trans>
      , icon   : <HomeIcon color='primary' />
      , link   : '/home'
      , access : [0, 1, 2]
    }
    , {
      text     : <Trans>User profile</Trans>
      , icon   : <AccountCircleIcon color='primary' />
      , link   : '/user'
      , access : [0, 1, 2]
    }
    , {
      text     : <Trans>Admin</Trans>
      , icon   : <AdminPanelSettingsIcon color='primary' />
      , link   : '/admin'
      , access : [1, 2]
    }
    , {
      text     : <Trans>Statistics</Trans>
      , icon   : <InsertChartIcon color='primary' />
      , link   : '/statistics'
      , access : [0, 1, 2]
    }
    , {
      text     : <Trans>Help</Trans>
      , icon   : <HelpIcon color='primary' />
      , link   : '/support'
      , access : [0, 1, 2]
    }
  ]

  return (
      <Box sx={{ display: 'flex' }}>
          <Drawer
            variant="permanent"
            sx={{
              width                  : drawerWidth
              , flexShrink           : 0
              , '& .MuiDrawer-paper' : { width: drawerWidth, boxSizing: 'border-box' }
            }}
          >
            <Toolbar sx={{ justifyContent: 'center', pt: 2, pb: 2 }} >
              <img src='../assets/logo_adams.png' alt='ADaMS logo' style={{ width: '75%' }} />
            </Toolbar>
            <Box sx={{ overflow: 'auto' }}>
              {Object.keys(init).length &&
                <>
                  <Box display='flex' justifyContent='center' mt={1} mb={2}>
                    {/* Nice jsx way of conditional (switch) rendering {
                      {
                        police    : <LocalPoliceOutlined sx={{ fontSize: 54 }} />
                        , hospital  : <LocalHospitalOutlined />
                        , insurance : <AccountBalanceOutlined />
                      }[user.organization]
                    } */}
                    <Badge
                      color='default'
                      overlap='circular'
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={<SecurityOutlinedIcon />}
                      // invisible={user.role !== 'admin'}
                      invisible={true}
                    >
                      <OrganizationIcon organization={init.user.organization} style={{ color: '#4a4a4a', fontSize: 36 }} />
                    </Badge>
                  </Box>
                  <Typography variant='h6' align='center'>{init.user.name || init.user.surname ? `${init.user.name} ${init.user.surname}` : init.user.username}</Typography>
                  <Typography sx={{ mb: 2 }} align='center'>{init.user.department}</Typography>
                  {/* <Typography>{organizations.find(o => o.value === init.user.organization).label}</Typography> */}
                  <Divider />
                  <List>
                    {mainPanelElements
                      .filter(elem => elem.access.includes(init.user.type))
                      .map(elem => (
                        <ListItem key={`${elem.text}-${randStr()}`} disablePadding>
                          <NavLink
                            style={({ isActive }) => ({ ...styles.navLink, backgroundColor: isActive ? theme.palette.action.selected : '' })}
                            to={elem.link || ''}
                            state={{
                              token
                              , init
                              , organizations
                            }}
                          >
                            <ListItemButton onClick={elem.onClick}>
                              <ListItemIcon>
                                  {elem.icon}
                              </ListItemIcon>
                              <ListItemText primary={elem.text} />
                            </ListItemButton>
                          </NavLink>
                        </ListItem>
                      ))}
                  </List>
                  <Divider />
                  <List>
                    {subPanelElements.map(elem => {
                      if (elem.type === 'switch') {
                        return (
                          <ListItem key={elem.text}>
                            <FormControlLabel control={<Switch />} label={elem.text} onChange={e => elem.action(e)} />
                          </ListItem>
                        )
                      }
                      if (elem.type === 'button') {
                        return (
                          <ListItem disablePadding key={`${elem.text}-${randStr()}`}>
                            <ListItemButton onClick={elem.action}>
                              <ListItemIcon>
                                {elem.icon}
                              </ListItemIcon>
                              <ListItemText primary={elem.text} />
                            </ListItemButton>
                          </ListItem>
                        )
                      }
                      return ''
                    })}
                </List>
              </>}
            </Box>
          </Drawer>
      </Box>
  )
}
