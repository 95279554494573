import { useCallback, useEffect, useState } from 'react'
import { Box, Divider, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, DatePicker, MobileDatePicker } from '@mui/x-date-pickers/'
import { useFormContext, Controller } from 'react-hook-form'
import moment from 'moment'

const FormElement = ({ elem, isMobile, form, formList }) => {
  const { control } = useFormContext()

  if (elem.type === 'datetime') {
    return (
        <FormControl key={`${form.type}-${elem.type}-${elem.label}`} margin='normal'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              name={`${formList[form.number]}.${elem.label}`}
              control={control}
              render={({ field: { value, onChange } }) => (
                isMobile
                  ? <MobileDateTimePicker
                    label={elem.label}
                    disableFuture
                    value={value ? moment(value) : null}
                    onAccept={date => {
                      onChange(date)
                    }}
                  />
                  : <DateTimePicker
                    label={elem.label}
                    disableFuture
                    value={value ? moment(value) : null}
                    onAccept={date => {
                      onChange(date)
                    }}
                    viewRenderers={{
                      hours   : renderTimeViewClock
                      , minutes : renderTimeViewClock
                      , seconds : renderTimeViewClock
                    }}
                  />
              )
              }
            />
          </LocalizationProvider>
        </FormControl>
    )
  }

  if (elem.type === 'date') {
    return (
        <FormControl key={`crash-${elem.type}-${elem.label}`} margin='normal'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              name={`${formList[form.number]}.${elem.label}`}
              control={control}
              render={({ field: { value, onChange } }) => (
                isMobile
                  ? <MobileDatePicker
                    label={elem.label}
                    disableFuture
                    defaultValue={value ? moment(value) : null}
                    onAccept={date => {
                      onChange(date)
                    }}
                  />
                  : <DatePicker
                    label={elem.label}
                    disableFuture
                    defaultValue={value ? moment(value).toJSON() : null}
                    onAccept={date => {
                      onChange(date)
                    }}
                  />
              )}
            />
          </LocalizationProvider>
        </FormControl>
    )
  }

  if (elem.type === 'select') {
    return (
      <FormControl key={`${elem.type}-${elem.label}`} margin='normal'>
        <InputLabel id={`select-${elem.label}`}>{elem.label}</InputLabel>
        <Controller
          name={`${formList[form.number]}.${elem.label}`}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              labelId={`select-${elem.label}`}
              id={`select-${elem.label}`}
              label={elem.label}
              value={field.value}
              onChange={field.onChange}
              startAdornment={elem.prefix && <InputAdornment>{elem.prefix}&nbsp;</InputAdornment>}
            >
              {elem.options.map(opt => {
                if (field.name.includes('Nationality') || field.name.includes('country')) {
                  return (
                    <MenuItem key={opt.label} value={opt.value}>
                      <Box display='flex'>
                        <Box
                          height='1.5rem'
                          border='1px solid #c4c4c4'
                          borderRadius={1}
                          sx={{
                            aspectRatio           : '4/3'
                            , backgroundImage     : `url('${opt.flag}')`
                            , backgroundSize      : 'cover'
                            , backgroundPosition  : 'center center'
                          }}
                          >
                          {/* <img height='100%' src={`${opt.flag}`} style={{ border: '1px solid #c4c4c4' }} /> */}
                        </Box>
                        <Typography ml={3}>{opt.label}</Typography>
                      </Box>
                    </MenuItem>
                  )
                } else {
                  return opt.value && opt.value % 100 === 0
                    ? <ListSubheader key={`${opt.label}-sub`}>{opt.label}</ListSubheader>
                    : <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
                }
              })}
            </Select>
          )}
        />
      </FormControl>
    )
  }

  return (
    <FormControl key={`${elem.type}-${elem.label}`}>
      <Controller
        name={`${formList[form.number]}.${elem.label}`}
        control={control}
        render={({ field }) => (
          <TextField
            margin='normal'
            fullWidth
            required={elem.isRequired}
            id={elem.label}
            label={elem.label}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />
    </FormControl>
  )
}

const FilterForm = ({
  isMobile
  , submitRef
  , form
  , formModel
  , formList
  , formArray
  , handleCrashSubmit
  , isFilterForm
}) => {
  const { control, setValue, handleSubmit, watch } = useFormContext()
  const [secondColStart] = useState(Math.ceil(formModel[form.type].elements.length / 2))
  console.log(formArray, form.type)

  return (
    <Box
      // name={form.type}
      component="form"
      ref={submitRef}
      onSubmit={handleSubmit(handleCrashSubmit)}
      display='flex'
      gap={2}
      pl={1}
      pr={1}
      noValidate
    >
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        minWidth='50%'
      >
      {formModel[form.type].elements
        .slice(0, secondColStart)
        .map(elem => (<FormElement
          elem={elem}
          isMobile={isMobile}
          form={form}
          formList={formList}
        />))}
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        minWidth='50%'
      >
      {formModel[form.type].elements
        .slice(secondColStart)
        .map(elem => (<FormElement
          elem={elem}
          isMobile={isMobile}
          form={form}
          formList={formList}
        />))}
      </Box>
    </Box>
  )
}

export default FilterForm
