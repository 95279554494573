import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import SecurityIcon from '@mui/icons-material/Security'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import { t } from '@lingui/macro'

const organizations = [
  { value: 0, label: t`Admin`, icon: (isMobile, style) => isMobile ? <SecurityOutlinedIcon /> : <SecurityIcon sx={style} /> }
  , { value: 1, label: t`Police`, icon: (isMobile, style) => isMobile ? <LocalPoliceOutlinedIcon /> : <LocalPoliceIcon sx={style} /> }
  , { value: 2, label: t`Healthcare`, icon: (isMobile, style) => isMobile ? <LocalHospitalOutlinedIcon /> : <LocalHospitalIcon sx={style} /> }
  , { value: 3, label: t`Insurance`, icon: (isMobile, style) => isMobile ? <AccountBalanceOutlinedIcon /> : <AccountBalanceIcon sx={style} /> }
]
export default function OrganizationIcon ({ organization, isMobile, style }) {
  if (Number.isInteger(organization)) {
    return organizations.find(o => o.value === organization).icon(isMobile, style)
  }
  return organizations.find(o => o.label === organization).icon(isMobile, style)
}
