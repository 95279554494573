import { Routes, Route } from 'react-router-dom'
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import useWindowSize from './hooks/useWindowSize'
import { InitProvider } from './context/initContext'
import Login from './pages/Login'
import Home from './pages/Home'
import Signup from './pages/Signup'
import User from './pages/User'
import Admin from './pages/Admin'
import Statistics from './pages/Statistics'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#10171f'
    }
  }
  , mixins: {
    drawerWidth       : 256
    , formDrawerWidth : 320
    , toolbar         : {
      minHeight: 64
    }
  }
  , components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor                                   : '#ebebeb #2b2b2b'
          , '&::-webkit-scrollbar, & *::-webkit-scrollbar' : {
            backgroundColor: '#ebebeb'
          }
          , '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius      : 8
            , backgroundColor : '#6b6b6b'
            , minHeight       : 24
            , border          : '3px solid #2b2b2b'
          }
          , '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595'
          }
          , '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595'
          }
          , '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595'
          }
          , '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#ff0000'
          }
        }
      }
    }
  }
})

export default function App () {
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'))
  const windowSize = useWindowSize()
  console.log('WS', windowSize)

  return (
    <InitProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<Login isMobile={isMobile} />} />
          <Route path='/home' element={<Home isMobile={isMobile} />} />
          <Route path='/signup' element={<Signup isMobile={isMobile} />} />
          <Route path='/user' element={<User isMobile={isMobile} />} />
          <Route path='/admin' element={<Admin isMobile={isMobile} />} />
          <Route path='/statistics' element={<Statistics isMobile={isMobile} />} />
        </Routes>
      </ThemeProvider>
    </InitProvider>
  )
}
