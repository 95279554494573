import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import {
  Alert
  , Box
  , CssBaseline
  , Divider
  , MobileLayersPanel
  , Toolbar
  , Typography
  , useTheme
} from '@mui/material/'
import { LogoutSharp } from '@mui/icons-material'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import MobileTopNav from '../components/mobile/MobileTopNav'
import MobileBottomNav from '../components/mobile/MobileBottomNav'
import DesktopNav from '../components/desktop/DesktopNav'
import UserForm from '../components/forms/UserForm'
import FullPageProgress from '../components/FullPageProgress'

/*
Fields editable by user:
- name
- surname
- department
- password
*/

// eslint-disable-next-line no-restricted-globals
const url = new URL('/api/user', location)

export default function User ({ isMobile }) {
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const user = location.state?.user || null
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest, reset } = useHttp()
  const [userData, setUserData] = useState({})

  const subPanelElements = [
    {
      text       : t`Logout`
      , icon     : <LogoutSharp color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : logout
    }
  ]

  const getUser = useCallback(() => {
    sendRequest({
      url
      , token
      , manageResData: res => {
        setUserData(res.user)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    getUser()
  }, [getUser])

  return (
    <Box component="main">
      <CssBaseline />
      {isMobile
        ? <>
            <MobileTopNav token={token} user={user} />
            <Toolbar />
        </>
        : <DesktopNav
          token={token}
          user={user}
          subPanelElements={subPanelElements}
        />}
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        pl={!isMobile && `${drawerWidth}px`}
        // sx={theme => !isMobile && { [theme.breakpoints.down('lg')]: { ml: `${drawerWidth}px` } }}
      >
        <Typography
          sx={isMobile ? { mt: 2, mb: 1 } : { mt: 4, mb: 4 }}
          variant={isMobile ? 'h4' : 'h3'}
          align='center'
        >
          <Trans>Profile</Trans>
        </Typography>
        <Divider flexItem variant='middle' />
        <Box
          width={isMobile ? '90%' : '80%'}
          maxWidth='sm'
          // maxWidth={isMobile ? 'xs' : 'sm'}
         >
          <UserForm isMobile={isMobile} token={token} user={userData}/>
        </Box>
        {error && <Alert sx={{ mt: 2 }} severity="error">
          <Trans>There was an error: {error}.</Trans>
        </Alert>}
        {isMobile && <MobileBottomNav />}
        <FullPageProgress isOpen={isLoading} isMobile={isMobile} />
      </Box>
    </Box>
  )
}
